import logoBeta from './logo-beta.svg'
import logoSolo from './logo-solo.svg'
import logoUnrealty from './logo-realty.svg'
import logoInversed from './logo-inversed.svg'
import Link from 'next/link'
import { HAVE_WINDOW } from '@unreserved-frontend-v2/utils/window'
import { clearStoredScrollPos } from '../hooks/use-scroll-resortation/use-scroll-restoration'

export interface LogoProps {
  width?: string
  isSolo?: boolean
  isUnrealty?: boolean
  isInversed?: boolean
  href?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export function Logo({
  width = '146',
  isSolo = false,
  isUnrealty = false,
  isInversed = false,

  // TODO: find the right place for this url when we add zones and inner pages
  href = '/',
  onClick,
}: LogoProps) {
  /**
   * This is to ensure whenever the logo is clicked, it takes the user to the top of the page
   * and doesn't use a previously saved scroll position
   */
  const clearScrollRestoration = (e: React.MouseEvent<HTMLElement>) => {
    onClick?.(e)
    if (HAVE_WINDOW) {
      clearStoredScrollPos(href)
    }
  }

  let DisplayLogo = isSolo ? logoSolo : logoBeta
  if (isUnrealty) {
    DisplayLogo = logoUnrealty
  }
  if (isInversed) {
    DisplayLogo = logoInversed
  }

  return (
    <div data-testid="site-logo" onClick={clearScrollRestoration}>
      <Link href={href} className="inline-block">
        <DisplayLogo width={width} alt="Unreserved Logo" />
      </Link>
    </div>
  )
}

export default Logo
